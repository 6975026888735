import * as React from 'react';
import { Box, Button, Container, Grid, MenuItem, TextField, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { Close, Done, Print } from '@mui/icons-material';

import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import PageDefault from '../../components/PageDefault';
import api from '../../services/api';
import apiDAM from '../../services/apiDAM';

export default function ListChargers() {
    const [cobrancas, setCobrancas] = React.useState([]);
    const [situacaoForm, setSituacaoForm] = React.useState('todas');
    const [nameForm, setNameForm] = React.useState('');
    const [anoForm, setAnoForm] = React.useState(moment().format('YYYY'));
    const [searchTerm, setSearchTerm] = React.useState('');
    const [carregando, setCarregando] = React.useState(false);
    const [selecionados, setSelecionados] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
      page: 0,
      pageSize: 20,
    });
    const [totalElements, setTotalElements] = React.useState(0);

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const contribuinteID = queryParams.get('contribuinte');

    React.useEffect(() => {
        setCarregando(true);

        let query = contribuinteID ? `contribuinteId=${contribuinteID}&` : '';
        query = query.concat(searchTerm !== '' ? `contribuinte=${searchTerm}&` : '');
        query = query.concat(situacaoForm !== 'todas' ? `situacao=${situacaoForm}&` : '');
        query = query.concat(`ano=${anoForm}&`);
        
        api.get(`/cobranca?${query}page=${paginationModel.page}&size=${paginationModel.pageSize}`)
        .then(res => {
            const aux = [];
            res.data.content.forEach(({ id, contribuinte, endereco, valorTotal, situacao, valorPago }) => {
                const numero = endereco.numero === 0 ? 'S/N' : endereco.numero;
                aux.push({
                    id,
                    contribuinte: contribuinte.nome,
                    documento: contribuinte.cpfCnpj.length === 11 ? contribuinte.cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.***.***-$4") : contribuinte.cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
                    endereco: endereco.rua + ', ' + numero + ', ' + endereco.bairro,
                    valorDevido: valorTotal.toFixed(2),
                    valorPago: valorPago ? valorPago.toFixed(2) : '',
                    situacao
                })
            });
            setCobrancas(aux);
            setTotalElements(res.data.totalElements);
            setCarregando(false);
        })
        .catch(err => {
            if (err.response) {
                const message = err.response.data.message;
                toast.error(message);
            }
            setCobrancas([])
            setCarregando(false);
        });
    }, [paginationModel.page, paginationModel.pageSize, situacaoForm, anoForm, contribuinteID, searchTerm]);

    function printCharger() {
        const cobIdSelecionada = selecionados[0];
        const status = cobrancas.filter(item => (item.id === cobIdSelecionada))[0].situacao;

        if (['Parcial', 'Aberto'].includes(status)) {
            api.get(`/cobranca/${cobIdSelecionada}`)
            .then(res => {
                const { data } = res;

                const dataVencimento = moment(data.dataVencimento, 'YYYY-MM-DD');
                const enderecoCobranca = data.contribuinte.endereco;
                const enderecoImovel = data.endereco;
                const contribuinteAux = data.contribuinte;
                const cpfFormatado = contribuinteAux.cpfCnpj.length === 11 ? contribuinteAux.cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : contribuinteAux.cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

                const dataPy = {
                    'cpf': cpfFormatado,
                    'razaoSocial': contribuinteAux.nome,
                    'enderecoCobranca': enderecoCobranca.rua + ', ' + enderecoCobranca.numero + ', ' + enderecoCobranca.bairro,
                    'enderecoImovel': enderecoImovel.rua + ', ' + enderecoImovel.numero + ', ' + enderecoImovel.bairro,
                    "damNumero": data.numDoc + "/" + data.exercicio,
                    "numDoc": data.numDoc + "/" + data.exercicio,
                    "exercicio": data.exercicio,
                    "periodoReferencia": "" + data.mesReferencia + "/" + data.anoReferencia,
                    "vencimento": dataVencimento.format('DD/MM/YYYY'),
                    "valorApurado": data.valorApurado,
                    "valorMulta": data.valorMulta,
                    "valorJuros": data.valorJuros,
                    "valorTotal": data.valorTotal,
                    'identificador': data.identificador
                }
                
                apiDAM.post(`/cobranca?com-aviso=False`, dataPy)
                .then(res => {
                    setCarregando(false);
                    const blob = new Blob([res.data], {
                        type: 'application/pdf'
                    });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                })
                .catch(err => {
                    setCarregando(false);
                    const message = err.response.data.message;
                    toast.error(message);
                })
            })
            .catch(err => {
                setCarregando(false);
                const message = err.response.data.message;
                toast.error(message);
            })
        } else {
            toast.warning('Só é possível imprimir uma cobrança com valor em aberto!')
        }
    }

    const columns = [
        { field: 'id', headerName: 'DAM', width: 80 },
        { field: 'contribuinte', headerName: 'Contribuinte', width: 250 },
        //{ field: 'documento', headerName: 'Documento', width: 150 },
        { field: 'endereco', headerName: 'Endereço', width: 400 },
        { field: 'valorDevido', headerName: 'Valor (R$)', width: 100 },
        { field: 'valorPago', headerName: 'Pago (R$)', width: 120 },
        { field: 'situacao', headerName: 'Status', width: 100 }
    ];

    return (
        <PageDefault carregando={false} title='Cobranças Geradas'>
            <Container component="main" maxWidth="sm" sx={{ mb: 0 }}>
                <Grid container spacing={3} mt={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="situacao"
                            select
                            fullWidth
                            required
                            label="Situação"
                            value={situacaoForm}
                            onChange={e => {
                                setSituacaoForm(e.target.value);
                                setPaginationModel({
                                    ...paginationModel,
                                    page: 0
                                })
                            }}
                            disabled={carregando}
                        >
                            <MenuItem value='todas'>Todas</MenuItem>
                            <MenuItem value='Aberto'>Aberto</MenuItem>
                            <MenuItem value='Parcial'>Parcial</MenuItem>
                            <MenuItem value='Pago'>Pago</MenuItem>
                            <MenuItem value='Cancelado'>Cancelado</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="anoForm"
                            select
                            fullWidth
                            required
                            label="Ano Referência"
                            value={anoForm}
                            onChange={e => (
                                setAnoForm(e.target.value)
                            )}
                        >
                            <MenuItem value='2022'>2022</MenuItem>
                            <MenuItem value='2023'>2023</MenuItem>
                            <MenuItem value='2024'>2024</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="search"
                            name="search"
                            label="Nome do contribuinte"
                            value={nameForm}
                            onChange={e => setNameForm(e.target.value)}
                            variant='standard'
                            fullWidth
                            sx={{mr: 1}}
                            />
                    </Grid>
                </Grid>
                <Button 
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    startIcon={<SearchIcon />} onClick={() => {
                    setSearchTerm(nameForm);
                    setPaginationModel({
                        ...paginationModel,
                        page: 0
                    });
                }}>Pesquisar</Button>
            </Container>
            <div>
                <Tooltip title="Pagar cobrança">
                    <span>
                        <Button startIcon={<Done />} disabled={selecionados.length !== 1 || ['Cancelado', 'Pago'].includes(situacaoForm)} onClick={() => {
                            const status = cobrancas.filter(item => (item.id === selecionados[0]))[0].situacao;
                            if (['Parcial', 'Aberto'].includes(status)) {
                                navigate(`/pagar-cobranca?numero=${selecionados[0]}`)
                            } else {
                                toast.warning('Só é possível pagar uma cobrança com valor em aberto!')
                            }
                        }}>
                            Pagar
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title="Cancelar cobrança">
                    <span>
                        <Button startIcon={<Close />} disabled={selecionados.length !== 1 || ['Cancelado', 'Pago'].includes(situacaoForm)} 
                            onClick={() => {
                            const status = cobrancas.filter(item => (item.id === selecionados[0]))[0].situacao;
                            if (['Aberto'].includes(status)) {
                                navigate(`/cancelar-cobranca?numero=${selecionados[0]}`)
                            } else {
                                toast.warning('Só é possível cancelar uma cobrança em aberto!')
                            }
                        }}>
                            Cancelar
                        </Button>
                    </span>
                </Tooltip>
                <Tooltip title="Imprimir cobrança">
                    <span>
                        <Button startIcon={<Print />} disabled={selecionados.length !== 1 || ['Aberto', 'Parcial'].includes(situacaoForm)} onClick={() => {
                            printCharger();
                        }}>
                            Imprimir
                        </Button>
                    </span>
                </Tooltip>
            </div>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    loading={carregando}
                    rows={cobrancas}
                    columns={columns}
                    paginationMode='server'
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[paginationModel.pageSize]}
                    rowCount={totalElements}
                    //keepNonExistentRowsSelected
                    checkboxSelection
                    onRowSelectionModelChange={(a) => {
                        setSelecionados(a);
                    }}
                    localeText={{
                        noRowsLabel: 'Sem cobranças encontradas'
                    }}
                />
            </Box>
        </PageDefault>
    );
}