import React from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/Login";
import NewBuilding from "./pages/NewBuilding";
import NewContributor from "./pages/NewContributor";
import ListImoveis from "./pages/ListImoveis";
import CalcularImposto from "./pages/CalcularImposto";
import CancelCharger from "./pages/CancelCharger";
import ListChargers from "./pages/ListChargers";
import PayCharger from "./pages/PayCharger";
import ListPayments from "./pages/ListPayments";
import GerarCobrancaAleatoria from "./pages/GerarCobrancaAleatoria";
import GerarCobrancaMassiva from "./pages/GerarCobrancaMassiva";

import { isAuthenticated, logout } from "./services/auth";

const PrivateRoute = ({ component: Component }) => {
  if (isAuthenticated()) {
    return <Component />;
  } else {
    return <Navigate to='/login' />;
  }
};

const Logout = () => {
  logout();
  return <Navigate to='/login' />;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoute component={Home} />,
    errorElement: <h3>Descupe, página não encontrada!</h3>
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/logout',
    element: <Logout />
  },
  //{
  //  path: '/contribuintes',
  //  element: <ListContributors />
  //},
  {
    path: '/novo-contribuinte',
    element: <PrivateRoute component={NewContributor} />
  },
  {
    path: '/editar-contribuinte/:contribuinteID',
    element: <PrivateRoute component={NewContributor} />
  },
  {
    path: '/contribuintes/:contribuinteID/imoveis',
    element: <PrivateRoute component={ListImoveis} />
  },
  {
    path: '/contribuintes/:contribuinteID/gerar-cobranca',
    element: <PrivateRoute component={GerarCobrancaAleatoria} />
  },  
  {
    path: '/contribuintes/:contribuinteID/imoveis/:imovelID/calcular-imposto',
    element: <PrivateRoute component={CalcularImposto} />
  },
  {
    path: '/contribuintes/:contribuinteID/novo-imovel',
    element: <PrivateRoute component={NewBuilding} />
  },
  {
    path: '/contribuintes/:contribuinteID/editar-imovel/:imovelID',
    element: <PrivateRoute component={NewBuilding} />
  },
  {
    path: '/imoveis',
    element: <PrivateRoute component={ListImoveis} />
  },
  {
    path: '/cobrancas',
    element: <PrivateRoute component={ListChargers} />
  },
  {
    path: '/gerar-cobranca-massiva',
    element: <PrivateRoute component={GerarCobrancaMassiva} />
  },
  {
    path: '/cancelar-cobranca',
    element: <PrivateRoute component={CancelCharger} />
  },
  {
    path: '/pagar-cobranca',
    element: <PrivateRoute component={PayCharger} />
  },
  {
    path: '/pagamentos',
    element: <PrivateRoute component={ListPayments} />
  }
  //{
  //  path: '/novo-imovel',
  //  element: <NewBuilding />
  //}
]);

export default function Routes() {
  return (
    <RouterProvider router={router} />
  );
}