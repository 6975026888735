import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { Button, MenuItem, Stack } from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import { KeyboardArrowDown } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { isAuthenticated } from '../../services/auth';

const menuAleatorio = [{
//   label: 'Contribuintes',
//   link: '/'
// }, {
//   label: '+ Contribuinte',
//   link: '/novo-contribuinte'
// }, {
  label: 'Imóveis',
  link: '/imoveis'
// }, {
//   label: 'Cobranças',
//   link: '/cobrancas'
}, {
//   label: 'Cancelar Cobrança',
//   link: '/cancelar-cobranca'
// }, {
//   label: 'Pagar Cobrança',
//   link: '/pagar-cobranca'
// }, {
//   label: 'Gerar Cobrança Massiva',
//   link: '/gerar-cobranca-massiva'
// }, {
  label: 'Pagamentos',
  link: '/pagamentos'
}];

const menuContribuintes = [{
  label: 'Listar Contribuintes',
  link: '/'
}, {
  label: 'Cadastrar Contribuinte',
  link: '/novo-contribuinte'
}];

const menuCobrancas = [{
  label: 'Listar Cobranças',
  link: '/cobrancas'
}, {
  label: 'Cancelar Cobrança',
  link: '/cancelar-cobranca'
}, {
  label: 'Pagar Cobrança',
  link: '/pagar-cobranca'
}, {
  label: 'Gerar Cobrança Massiva',
  link: '/gerar-cobranca-massiva'
}];

export default function Header() {
  const [menuOpen, setMenuOpen] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen('');
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar position="static" sx={{ mb: 2 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            
          <Stack direction="row" sx={{
            justifyContent: "center",
            alignItems: "center",
            display: { xs: 'flex', md: 'none' }
          }}>
            {/* inicio do responsivo menor */}
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="top"
                open={open}
                onClose={toggleDrawer(false)}
                PaperProps={{
                  sx: {
                    top: 'var(--template-frame-height, 0px)',
                  },
                }}
              >
                <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton onClick={toggleDrawer(false)}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>
                  {isAuthenticated() && <div>
                  {menuContribuintes.map(({ label, link }) => (
                    <MenuItem component={Link} to={link}>{label}</MenuItem>
                  ))}
                  {menuAleatorio.map(({ label, link }) => (
                    <MenuItem component={Link} to={link}>{label}</MenuItem>
                  ))}
                  {menuCobrancas.map(({ label, link }) => (
                    <MenuItem component={Link} to={link}>{label}</MenuItem>
                  ))}                  
                  </div>}

                  <Divider sx={{ my: 3 }} />

                  {isAuthenticated() ?
                    <MenuItem>
                      <Button color="error" variant="contained" href="/logout" fullWidth>
                        Sair
                      </Button>
                    </MenuItem> :
                    <MenuItem>
                      <Button color="success" variant="contained" href="/login" fullWidth>
                        Entrar
                      </Button>
                    </MenuItem>
                  }
                </Box>
              </Drawer>
            </Box>
            <Stack alignItems="center" direction="row">
              <AdbIcon sx={{ mr: 1 }} />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                AEC Olivêdos
              </Typography>
            </Stack>
            {/* fim do responsivo menor */}
          </Stack>

          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            AEC Olivêdos
          </Typography>

          {isAuthenticated() ? 
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Button
              id="basic-button"
              aria-controls={menuOpen === 'contribuintes' ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen === 'contribuintes' ? 'true' : undefined}
              onClick={event => {
                setAnchorEl(event.currentTarget);
                setMenuOpen('contribuintes')
              }}
              sx={{ my: 2, color: 'white' }}
              endIcon={<KeyboardArrowDown />}
            >
              Contribuintes
          </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen === 'contribuintes'}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {menuContribuintes.map(({ label, link }) => (
                <MenuItem component={Link} to={link}>{label}</MenuItem>
              ))}
            </Menu>

            {menuAleatorio.map(({ label, link}) => (
              <Button
                href={link}
                key={label}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {label}
              </Button>
            ))}

            <Button
              id="basic-button"
              aria-controls={menuOpen === 'cobrancas' ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen === 'cobrancas' ? 'true' : undefined}
              onClick={event => {
                setAnchorEl(event.currentTarget);
                setMenuOpen('cobrancas')
              }}
              sx={{ my: 2, color: 'white' }}
              endIcon={<KeyboardArrowDown />}
            >
              Cobranças
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen === 'cobrancas'}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {menuCobrancas.map(({ label, link }) => (
                <MenuItem component={Link} to={link}>{label}</MenuItem>
              ))}
            </Menu>
          </Box> : 
          <Box sx={{ flexGrow: 1 }}></Box>}
          
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            {isAuthenticated() ?
            <Button href="/logout" variant="contained" color="error" sx={{ my: 1, mx: 1.5 }}>
              Sair
            </Button>
            :
            <Button href="/login" variant="contained" color="success" sx={{ my: 2 }}>
              Entrar
            </Button>}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}